import React, { useEffect } from "react";
import { GridLoader } from "react-spinners";
import { IJobInfo } from "../../api/types/jobs/IJobInfo";
import { FullLoaderStyled } from "../../components/styled/FullLoader.styled";
import { TemplateEditor } from "../../components/template/TemplateEditor";
import { EditorMode } from "../../components/template/types/EditorMode";
import { Color } from "../../constants/colors";
import { fetchDirectoryForJobInfo } from "../../store/directory/thunks/fetchDirectoryForJobInfo";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface Props {
  mode: EditorMode
  source?: IJobInfo
  onClose: () => void
}

export const ModifyTemplate = ({ mode, source, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((s) => s.jobData.data);
  const stats = useAppSelector((s) => s.jobData.stats);

  useEffect(() => {
    if (source) dispatch(fetchDirectoryForJobInfo(source));
  }, [source]);

  return data && data.id === source?.id ? (
    <TemplateEditor mode={mode} source={data} jobStats={stats} onClose={onClose} />
  ) : (
    <FullLoaderStyled>
      <GridLoader color={Color.brand} />
    </FullLoaderStyled>
  );
};

import { AssociateType } from "../../../components/filter/types/AssociateType";
import { ISelectItem } from "../../../components/selectList/types/ISelectItem";
import { IAssociateState } from "../types/IAssociateState";
import { getAllForOperator } from "./getAllForOperator";

export function selectMatchingItems(
  operatorIDs: number[],
  state: IAssociateState,
  selectedItems: ISelectItem[],
  operatorID: number,
  type: AssociateType,
) {
  try {
    const selectedTezIds = selectedItems.map((itm) => itm.external_id ?? itm.id);
    for (const id of operatorIDs) {
      const source = id === 10 ? state.tezTargets ?? [] : state.associated[id] ?? [];
      const itemIds = source.map((itm) => (id === 10 ? itm.external_id : itm.id));
      const all = getAllForOperator(id, type, state);
      const matching = all.filter((itm) => {
        const tezIds = typeof itm.tez_id === "number" ? [itm.tez_id] : itm.tez_id.split(",");
        for (const id of tezIds) {
          const found = selectedTezIds.includes(id) && !itemIds.includes(itm.id);
          if (found) return true;
        }
        return false;
      });
      if (matching.length > 0) {
        if (id === 10) {
          state.tezTargets = [...source, ...matching];
        } else {
          state.associated[id] = [...source, ...matching];
        }
      }
    }
  } catch (e) {
    console.log("selectMatchingItems", type, e);
  }
}

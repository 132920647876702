import { IAssociateState } from "../types/IAssociateState";

export const resetAssociated = (
  state: IAssociateState,
  withFilters: boolean,
) => {
  state.associated = {};
  state.tezTargets = [];
  state.suggested = {};
  state.pansions = [];
  if (withFilters) {
    state.filterResort = {};
    state.filterHotel = {};
  }
};

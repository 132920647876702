import { LoadingButton } from "@mui/lab";
import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { MdCheck, MdLink } from "react-icons/md";
import { IBaseItem } from "../../api/types/directory/IBaseItem";
import {
  createRegion,
  editRegion,
} from "../../store/associate/thunks/createRegion";
import { createTour, editTour } from "../../store/associate/thunks/createTour";
import { fetchAssociateRegions } from "../../store/associate/thunks/fetchAssociateRegions";
import { fetchAssociateResorts } from "../../store/associate/thunks/fetchAssociateResorts";
import { fetchAssociateTourFilters } from "../../store/associate/thunks/fetchAssociateTourFilters";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hasTezID } from "../../utils/hasTezID";
import { ColumnBodyStyled, ColumnStyled } from "../columns/ColumnBodyStyled";
import { AssociateType } from "../filter/types/AssociateType";
import { SelectField } from "../select/SelectField";
import { SelectListEmptyState } from "./SelectListEmptyState";
import { AutoCompleteItemStyled } from "./styled/ListItem.styled";

function titleFor(type: AssociateType): string {
  return type === AssociateType.regions ? "Создать регион" : "Создать тур";
}
function itemNameFor(type: AssociateType, plural?: boolean): string {
  return type === AssociateType.regions
    ? `курорт${plural ? "ы" : ""}`
    : `регион${plural ? "ы" : ""}`;
}

export const ItemCreator = ({
  type,
  operatorID,
}: {
  type: AssociateType;
  operatorID: number;
}) => {
  const dispatch = useAppDispatch();
  const ENABLED = [AssociateType.tours, AssociateType.regions];
  if (!ENABLED.includes(type)) return null;

  const loading = useAppSelector((s) =>
    s.associate.loadingKeys.includes(`${operatorID}`),
  );

  const sourceItems = useAppSelector((s) => {
    if (type === AssociateType.regions) {
      return s.associate.regions[operatorID] ?? [];
    }
    if (type === AssociateType.tours) {
      return s.associate.tourFilters[operatorID] ?? [];
    }
    return [];
  });

  const items = useAppSelector((s) => {
    if (type === AssociateType.regions) {
      return s.associate.resorts[operatorID] ?? [];
    }
    if (type === AssociateType.tours) {
      return s.associate.regions[operatorID] ?? [];
    }
    return [];
  });

  const country = useAppSelector((s) => s.associate.country);

  const [selected, setSelected] = useState<IBaseItem[]>([]);
  const [itemToEdit, setItemToEdit] = useState<number | undefined>(undefined);
  const [name, setName] = useState("");

  const canCreate = !loading && selected.length > 0 && name.trim().length > 0;
  const create = () => {
    if (!canCreate || !country) return;
    const ids = selected.map((itm) => itm.id).join(",");
    if (type === AssociateType.regions) {
      dispatch(
        itemToEdit
          ? editRegion({
              id: itemToEdit,
              operator: operatorID,
              name,
              country,
              resorts: ids,
            })
          : createRegion({ operator: operatorID, name, country, resorts: ids }),
      ).then(() => {
        setSelected([]);
        setName("");
        setItemToEdit(undefined);
        dispatch(fetchAssociateResorts({ operator: operatorID, country }));
        dispatch(fetchAssociateRegions({ operator: operatorID, country }));
      });
    }
    if (type === AssociateType.tours) {
      dispatch(
        itemToEdit
          ? editTour({
              id: itemToEdit,
              operator: operatorID,
              name,
              country,
              regions: ids,
            })
          : createTour({ operator: operatorID, name, country, regions: ids }),
      ).then(() => {
        setSelected([]);
        setName("");
        setItemToEdit(undefined);
        dispatch(fetchAssociateRegions({ operator: operatorID, country }));
        dispatch(fetchAssociateTourFilters({ operator: operatorID, country }));
      });
    }
  };

  const hasItems = items.length > 0;

  const setEditItemAndFillItsData = (val: number | undefined) => {
    if (val) {
      const item = sourceItems.find((itm) => itm.id == val);
      setName(item?.name ?? "");
    } else {
      const item = sourceItems.find((itm) => itm.id == itemToEdit);
      // User not edited the name
      if (item?.name == name) {
        setName("");
      }
    }
    setItemToEdit(val);
  };

  return (
    <ColumnStyled>
      <h5>{titleFor(type)}</h5>
      <ColumnBodyStyled minHeight={60}>
        {hasItems ? (
          <SelectField
            items={sourceItems}
            label={"или редактировать существующий"}
            value={itemToEdit}
            loading={loading}
            variant={"filled"}
            onSelect={(val) =>
              setEditItemAndFillItsData(val as number | undefined)
            }
            canClear={true}
          />
        ) : null}
        {hasItems ? (
          <Autocomplete
            sx={{ marginBottom: "8px" }}
            multiple
            autoComplete={true}
            loading={loading}
            options={items}
            disabled={loading}
            disableClearable={true}
            getOptionLabel={(option) => `${option.name}`}
            value={selected}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, val) => setSelected(val)}
            renderOption={(props, option, state) => (
              <AutoCompleteItemStyled
                key={`${option.name}.${option.id}`}
                {...props}
                selected={state.selected}
                highlighted={hasTezID(option.tez_id)}
              >
                {hasTezID(option.tez_id) ? <MdLink /> : null}
                {state.selected ? <MdCheck /> : null}
                {option.name}
                <small>id:{option.id}</small>
              </AutoCompleteItemStyled>
            )}
            ChipProps={{ size: "small" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"filled"}
                label={
                  selected.length > 0
                    ? `Выбранные ${itemNameFor(type, true)}`
                    : `Выберите ${itemNameFor(type, true)} из списка`
                }
                placeholder={"Поиск по названию"}
              />
            )}
          />
        ) : (
          <SelectListEmptyState isHidden={loading} />
        )}
        <TextField
          label={name.trim().length > 0 ? "Название" : "Введите название"}
          variant={"filled"}
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value ?? "")}
        />
        <LoadingButton
          loading={loading}
          disabled={!canCreate}
          variant="contained"
          onClick={() => create()}
        >
          {itemToEdit ? "Редактировать" : "Создать"}
        </LoadingButton>
      </ColumnBodyStyled>
    </ColumnStyled>
  );
};

import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";
import {
  BindAction,
  UnbindAction,
  UnbindActionType,
} from "../../../api/types/BindAction";
import { AssociateType } from "../../../components/filter/types/AssociateType";
import { SpacedFixedContainerStyled } from "../../../components/styled/SpacedContainerStyled";
import { resetAssociatedItems } from "../../../store/associate/associateSlice";
import {
  bindDirectoryItems,
  unbindDirectoryItems,
} from "../../../store/associate/thunks/bindDirectoryItems";
import { reloadAssociateData } from "../../../store/associate/thunks/reloadAssociateData";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hasTezID } from "../../../utils/hasTezID";

const typeToBindAction = (type: AssociateType): BindAction => {
  switch (type) {
    case AssociateType.pansions:
      return BindAction.bindPansion;
    case AssociateType.regions:
      return BindAction.bindRegions;
    case AssociateType.hotels:
      return BindAction.bindHotel;
    case AssociateType.roomTypes:
      return BindAction.bindRooms;
    case AssociateType.tours:
      return BindAction.bindTour;
    case AssociateType.createTours:
      return BindAction.bindTour;
    case AssociateType.createRegions:
      return BindAction.bindRegions;
    case AssociateType.cities:
      return BindAction.bindCity;
  }
};

const typeToUnbindAction = (type: AssociateType): UnbindActionType => {
  switch (type) {
    case AssociateType.hotels:
      return UnbindAction.unbindHotel;
    case AssociateType.roomTypes:
      return UnbindAction.unbindRoom;
    case AssociateType.pansions:
      return UnbindAction.unbindPansion;
    case AssociateType.regions:
      return UnbindAction.unbindRegion;
    case AssociateType.tours:
      return UnbindAction.unbindTour;
    case AssociateType.cities:
      return UnbindAction.unbindCity;
    default:
      return UnbindAction.unbindHotel;
  }
};
export const AssociateControlButtons = ({ type }: { type: AssociateType }) => {
  const dispatch = useAppDispatch();

  const action = typeToBindAction(type);

  const loading = useAppSelector((s) =>
    s.associate.loadingKeys.includes("bind"),
  );

  const tezItems = useAppSelector((s) =>
    s.associate.tezTargets.map((itm) => itm.external_id ?? itm.id),
  );

  const { associateItems, linked } = useAppSelector((s) => {
    const operators = s.associate.operators;
    const result: (number | string)[] = [];
    const linked: (number | string)[] = [];
    for (const operator of operators) {
      const items = s.associate.associated[operator.id];
      if (items) {
        result.push(...items.map((i) => i.local_id ?? i.id));
        linked.push(
          ...items
            .filter((i) => hasTezID(i.tez_id))
            .map((i) => i.local_id ?? i.id),
        );
      }
    }
    return { associateItems: result, linked };
  });

  const hasSelected = associateItems.length > 0 && tezItems.length > 0;
  const hasLinked = linked.length > 0;
  const canUnlink = hasLinked && !loading;
  const canSave = !loading && hasSelected;
  const resetAssociations = () => {
    dispatch(resetAssociatedItems(true));
  };

  const saveAssociations = () => {
    dispatch(
      bindDirectoryItems({
        action,
        tezID: tezItems.join(","),
        localIDs: associateItems,
      }),
    ).then(() => {
      resetAssociations();
      dispatch(reloadAssociateData(type));
    });
  };

  const removeAssociations = () => {
    dispatch(
      unbindDirectoryItems({
        action: typeToUnbindAction(type),
        localIDs: linked,
      }),
    ).then(() => {
      resetAssociations();
      dispatch(reloadAssociateData(type));
    });
  };

  if (action === BindAction.bindTourFilter) return null;
  return (
    <SpacedFixedContainerStyled>
      <Button
        variant="text"
        disabled={!hasSelected}
        onClick={resetAssociations}
        size={"small"}
      >
        Очистить выбор
      </Button>

      <LoadingButton
        loading={false}
        disabled={!canUnlink || loading}
        variant="contained"
        size={"small"}
        onClick={() => removeAssociations()}
      >
        Удалить ассоциации
      </LoadingButton>

      <LoadingButton
        loading={false}
        disabled={!canSave || loading}
        variant="contained"
        size={"small"}
        onClick={() => saveAssociations()}
      >
        Сохранить ассоциации
      </LoadingButton>
    </SpacedFixedContainerStyled>
  );
};

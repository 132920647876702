import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AssociateFieldsFilter } from "../../components/filter/AssociateFieldsFilter";
import {
  AssociateType,
  titleForAssociateType,
} from "../../components/filter/types/AssociateType";
import { resetAssociatedItems } from "../../store/associate/associateSlice";
import { useAppDispatch } from "../../store/hooks";
import { AssociateControlButtons } from "./associate/AssociateControlButtons";
import { AssociateEditor } from "./associate/AssociateEditor";

const pathParamToType = (param?: string | null): AssociateType => {
  switch (param) {
    case "pansions":
      return AssociateType.pansions;
    case "roomTypes":
      return AssociateType.roomTypes;
    case "associateRegions":
      return AssociateType.regions;
    case "associateTours":
      return AssociateType.tours;
    case "associateCities":
      return AssociateType.cities;
    default:
      return AssociateType.hotels;
  }
};
export const AssociateFields = () => {
  const { type } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetAssociatedItems(true));
  }, [type]);

  const associateType = pathParamToType(type);
  return (
    <Container maxWidth="xl" component={"div"}>
      <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
        <Grid item xs={12}>
          <h2>{titleForAssociateType(associateType)}</h2>
        </Grid>
        <Grid item xs={12}>
          <AssociateFieldsFilter type={associateType} />
        </Grid>
        {/*Displays rows of data to associate by operator and additional blocks to edit data*/}
        <AssociateEditor type={associateType} />
        {/*Bottom control button block*/}
        <AssociateControlButtons type={associateType} />
      </Grid>
    </Container>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { TEZ_OPERATOR } from "../../../api/types/directory/IOperator";
import { AssociateType } from "../../../components/filter/types/AssociateType";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { fetchAssociateDestinationCities } from "./fetchAssociateDestinationCities";
import { fetchAssociateHotels } from "./fetchAssociateHotels";
import { fetchAssociateRegions } from "./fetchAssociateRegions";
import { fetchAssociateResorts } from "./fetchAssociateResorts";
import { fetchAssociateRoomTypes } from "./fetchAssociateRoomTypes";
import { fetchAssociateTourFilters } from "./fetchAssociateTourFilters";
import { IAssociateParams } from "./types/IAssociateParams";

export const reloadAssociateData = createAsyncThunk<
  void,
  AssociateType,
  { state: RootState; dispatch: AppDispatch }
>("associate/reload", async (type, thunkAPI) => {
  const { associate } = thunkAPI.getState();
  const operators =
    associate.operators.length > 0
      ? [...associate.operators, TEZ_OPERATOR]
      : associate.operators;
  const country = associate.country;

  if (!country) return;

  for (const operator of operators) {
    const operatorId: number = operator.id;
    const selectedFilter =
      type === AssociateType.hotels
        ? associate.filterResort[operatorId] ?? null
        : associate.filterHotel[operatorId] ?? null;

    const params: IAssociateParams = {
      operator: operatorId,
      country,
      resort: selectedFilter?.id,
      hotel_id: selectedFilter?.id,
      region_id: associate.filterRegion[operatorId]?.id,
      tour_id: associate.filterTour[operatorId]?.id,
    };

    if (type === AssociateType.hotels) {
      thunkAPI.dispatch(fetchAssociateHotels(params));
      thunkAPI.dispatch(
        fetchAssociateResorts({ ...params, noSuggestions: true }),
      );
    } else if (type === AssociateType.pansions) {
      thunkAPI.dispatch(fetchAssociateRoomTypes(params));
      thunkAPI.dispatch(
        fetchAssociateHotels({ ...params, noSuggestions: true }),
      );
    } else if (type === AssociateType.regions) {
      thunkAPI.dispatch(fetchAssociateRegions(params));
    } else if (type === AssociateType.tours) {
      thunkAPI.dispatch(fetchAssociateTourFilters(params));
    } else if (type === AssociateType.createTours) {
      thunkAPI.dispatch(fetchAssociateResorts(params));
    } else if (type === AssociateType.cities) {
      thunkAPI.dispatch(fetchAssociateDestinationCities(params));
    }
  }
});
